.Home {
  text-align: center;
}

.Home-logo {
  animation: Home-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.Home-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Home-link {
  color: #61dafb;
}

.Home-api-response {
  margin-top: 10px;
  width: 50%;
  background-color: #434d63;
  display: inline-block;
  font-family: 'Lucida Console', monospace
}

@keyframes Home-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Home-details {
  margin-top: 2em;
  font-size: 14px;
  max-width: 50%;
}
.Home-details a {
  color: #61dafb;
}
.Home-details-links {
  text-align: left;
  display: inline-block;
}